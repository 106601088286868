<template>
  <!-- 采购价格维护 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="80px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="STYLE">
              <el-select v-model="form.style" value-key="id" clearable filterable @change="styleSelectChange">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="COLOR">
              <el-select v-model="form.color" clearable filterable>
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SIZE">

              <el-select v-model="form.sizes" clearable filterable>
                <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商">
              <el-select v-model="form.vendorId" clearable filterable multiple :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SKU">
              <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态">
              <el-select v-model="form.purchaseStatus" :placeholder="$t('page.selectPlaceholder')">
                <el-option label="启用" value="0" />
                <el-option label="禁用" value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生效时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="~"
                start-placeholder="生效开始时间"
                end-placeholder="失效结束时间"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <!--<router-link to="added" append><el-button class="mr-3" type="primary">新增</el-button></router-link>-->
            <!--<el-button type="primary" @click="downTemplate">模板下载</el-button>-->
            <!--<el-upload-->
            <!--ref="uploadForm"-->
            <!--action=""-->
            <!--class="el-button padding-none"-->
            <!--:http-request="uploadFile"-->
            <!--:limit="1"-->
            <!--:show-file-list="false"-->
            <!--:file-list="fileList"-->
            <!--:auto-upload="false"-->
            <!--:on-change="fileChange"-->
            <!--&gt;-->
            <!--<el-button type="primary">{{ $t('page.import') }}</el-button>-->
            <!--</el-upload>-->
            <!--<el-button type="primary" @click="allPrice()">批量修改价格</el-button>-->

          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <ImportButton v-permission="'export'" export-key="scm_purchase_price" :params="queryParameter" />
      </el-col>
    </el-row>
    <el-table
      ref="multipleTable"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="style,color,size" label="规格型号" width="180" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column prop="sku" label="SKU" width="180" align="center" sortable />
      <!--  <el-table-column prop="fnsku" label="FNSKU" width="150" align="center" sortable />
      <el-table-column prop="upc" label="UPC" width="140" align="center" sortable /> -->
      <el-table-column prop="vendorName" label="供应商" width="140" align="center" sortable />
      <el-table-column prop="purchasePrice" label="含税价格" width="140" align="center" sortable />
      <el-table-column prop="excludingTaxPrice" label="不含税价格" width="150" align="center" sortable />
      <el-table-column prop="currencyType" label="报价币种" width="120" align="center" sortable>
        <template slot-scope="scope">
          <!--  <p v-if="scope.row.currencyType == '1'">美元</p>
          <p v-if="scope.row.currencyType == '2'">人民币</p> -->
          {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
        </template>
      </el-table-column>
      <el-table-column label="是否含税" prop="isExcludingTax" width="120" align="center" sortable>
        <template slot-scope="scope">
          <p v-if="scope.row.isExcludingTax == '0'">是</p>
          <p v-if="scope.row.isExcludingTax == '1'">否</p>
        </template>
      </el-table-column>
      <el-table-column prop="dollarPrice" label="美元单价" width="120" align="center" sortable />
      <el-table-column prop="purchaseStatus" label="状态" width="100" align="center" sortable>
        <template slot-scope="scope">
          <p v-if="scope.row.purchaseStatus == '0'">启用</p>
          <p v-if="scope.row.purchaseStatus == '1'">禁用</p>
        </template>
      </el-table-column>
      <el-table-column prop="effectiveStartDate" label="生效时间" width="120" align="center" sortable />
      <el-table-column prop="effectiveEndDate" label="失效时间" width="120" align="center" sortable />

      <el-table-column prop="createByName" label="创建人" width="100" align="center" sortable />
      <el-table-column prop="createTime" label="创建时间" width="150" align="center" sortable />
      <el-table-column fixed="right" align="center" :label="$t('page.operate')" width="150">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handelpurchase(scope.row.id,true)">查看
            <!-- <p v-if="scope.row.pickStatus == 0">查看</p> -->
          </el-button>
          <!--<el-button type="text" size="small" @click="handelpurchase(scope.row.id,false)">修改-->
          <!-- <p v-if="scope.row.pickStatus">修改</p> -->
          <!--</el-button>-->
          <!--<el-button type="text" size="small" @click="handleDelete(scope.row.id,)">删除-->
          <!-- <p v-if="scope.row.pickStatus">修改</p> -->
          <!--</el-button>-->

        </template>
      </el-table-column>

    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="_pricepage"
      />
    </div>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      :visible.sync="importDialog"
      width="550px"
      :close-on-click-modal="false"
      :before-close="closeImportDialog"
    >
      <span v-if="fileList != ''" class="info-message">{{ $t('page.ConfirmImport') }} {{ fileList[0].name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeImportDialog">取消</el-button>
        <el-button type="primary" @click="importDialogSubmit">导入详情</el-button>
      </span>
    </el-dialog>
    <!-- 导入详情 -->
    <el-dialog :visible.sync="uploadTableVisible" width="80%" title="导入详情" append-to-body>
      <el-button type="primary" @click="handleDeletedetails()">删除</el-button>
      <el-table
        ref="uploadTableDataRef"
        :data="importdata"
        :header-cell-style="{background:'#fafafa'}"
        tooltip-effect="dark"
        max-height="550px"
        :row-class-name="tableRowClassName"
        @selection-change="dialogSelectionChange"
      >
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
          <template slot-scope="scope">{{ (pager.current - 1) * pager.size + scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="error" label="错误信息" />
        <el-table-column prop="style" label="style" />
        <el-table-column prop="color" label="color" />
        <el-table-column prop="size" label="size" />

        <el-table-column prop="sku" label="SKU" />
        <el-table-column prop="fnsku" label="FNSKU" />
        <el-table-column prop="upc" label="UPC" />

        <el-table-column prop="vendorName" label="供应商" />

        <el-table-column prop="purchasePrice" label="含税价格" width="140" align="center" sortable />
        <el-table-column prop="excludingTaxPrice" label="不含税价格" width="150" align="center" sortable />
        <el-table-column prop="currencyType" label="币种" width="100" align="center" sortable>
          <template slot-scope="scope">
            <!-- <p v-if="scope.row.currencyType == 'USD'">美元</p>
            <p v-if="scope.row.currencyType == 'CNY'">人民币</p> -->
            {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
          </template>
        </el-table-column>

      </el-table>
      <el-row type="flex" justify="end" style="margin-top:20px;">
        <el-button @click="uploadTableVisible = false">取消</el-button>
        <el-button type="primary" :loading="Loading" @click="submitImportTable">提交</el-button>
      </el-row>
    </el-dialog>
    <!-- 批量修改价格 -->
    <el-dialog title="批量修改采购价格" :visible.sync="modifyDialogFormVisible">
      <el-form :model="modifyForm" label-width="100px">
        <el-form-item label="含税价格" prop="purchasePrice">
          <el-input v-model="modifyForm.purchasePrice" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="不含税价格" prop="excludingTaxPrice">
          <el-input v-model="modifyForm.excludingTaxPrice" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="币种" prop="currencyType">
          <el-select v-model="modifyForm.currencyType" :placeholder="$t('page.selectPlaceholder')">
            <!--  <el-option label="美元" value="1" />
            <el-option label="人民币" value="2" /> -->
            <el-option v-for="item in _getAllCommodityDict('CURRENCY_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :loading="modifyUpLoding" @click="modifySure()">批量提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { updatePriceBatch, addpurchasebatch, priceimport, findDownloadUrl, queryStyleList, querySizeList, queryColorList, pricepage, deleteprice, queryVendorList } from '@/api/scm-api'
import { downloads } from '@/utils'
import Pagination from '@/components/Pagination'
import ImportButton from '@/components/ExportFile'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  components: { Pagination, ImportButton },
  mixins: [commodityInfoDict],
  data() {
    return {
      vendorOptions: [],
      selectList: [],
      modifyDialogFormVisible: false,
      modifyUpLoding: false,
      modifyForm: {
        currencyType: '',
        purchasePrice: '',
        excludingTaxPrice: ''
      },
      importdata: [],
      importdialog: false,
      importDetaildialog: false,
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      showRow: false, // 切换显示input
      tableDatas: [],
      sidvoList: [],
      Loading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      file: '',
      fileList: [],
      form: {
        date: []
      },
      importDialog: false,
      uploadTableVisible: false
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      const { style, color, sizes, vendorId, sku, purchaseStatus } = this.form
      const [effectiveStartDate, effectiveEndDate] = this.form.date || []
      return Object.assign({}, this.pager, { style, color, sizes, vendorId: vendorId ? vendorId.toString() : '', sku, purchaseStatus }, { effectiveStartDate, effectiveEndDate })
    }

  },
  mounted() {
    this._pricepage()
    this._queryColorList()
    this._querySizeList()
    this._queryStyleList()
    this._queryVendorList()
  },
  methods: {
    //   供应商下拉数据
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    tableRowClassName({ row, rowIndex }) {
      console.log(row.error)
      if (row.error) {
        return 'warning-row'
      }
      return ''
    },
    async downTemplate() {
      const { datas } = await findDownloadUrl('SKU_PURCHASE_PRICE_IMPORT_TEMPLATE')
      downloads(datas)
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 选择style 带出color
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.styleName === value) || []
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // 点击查询获取信息
    queryClick(page) {
      this.pager.current = 1
      this._pricepage(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _pricepage(pager) {
      try {
        this.TableLoading = true
        pager && Object.assign(this.pager, pager)
        const { datas } = await pricepage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    // 根据ID删除采购价格信息
    handleDelete(row) {
      this.$confirm(this.$t('page.SureToDeleteDetail'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(() => {
        this._deleteprice(row)
      })
    },
    // 通过id删除采购价格信息
    async _deleteprice(row) {
      // msg,
      const { datas } = await deleteprice(row)
      this.$notify({
        title: datas,
        message: datas,
        type: 'success'
      })
      this._pricepage(this.queryParameter)
    },
    // 修改、查看
    handelpurchase(row, disabled) {
      this.$router.push({
        path: 'added',
        append: true,
        query: { 'id': row, 'disabled': disabled }
      })
    },
    // 导入
    beforeUpload(file) {
      const Xls = file.name.split('.')
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error(this.$t('page.UploadSizeTips'))
        return false
      }
      if (Xls[Xls.length - 1] === 'xls' || Xls[Xls.length - 1] === 'xlsx') {
        return true
      } else {
        this.$message.error(this.$t('page.UploadTypeTips'))
        return false
      }
    },
    // 导入
    async uploadFile() {
      const form = new FormData()
      form.append('file', this.file)
      // form.append('siteId', this.outboundDetailForm.siteId)
      // form.append('siteCode', this.outboundDetailForm.siteCode)
      const { datas } = await priceimport(form)
      datas.map(v => {
        v.outPairs = v.unSalesPairs
      })
      this.importdata = datas
      this.uploadTableVisible = true
      // 隐藏弹窗清空数据
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeUpload(file)) {
          this.importDialog = true
        }
      } else {
        this.importDialog = false
      }
    },
    closeImportDialog() {
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    importDialogSubmit() {
      this.$refs.uploadForm.submit()
    },
    dialogSelectionChange(val) {
      let data = ''
      this.sidvoList = []
      for (let i = 0; i < val.length; i++) {
        data = val[i]
        this.sidvoList.push(data)
      }
    },
    handleDeletedetails() {
      const data = this.$refs.uploadTableDataRef.selection
      data.map(e => {
        var index = this.importdata.findIndex((i) => {
          return e.sku === i.sku
        })
        if (index !== -1) {
          this.importdata.splice(index, 1)
        }
      })
      // sessionStorage.setItem('indatas', JSON.stringify(this.importdata))
    },
    submitImportTable() {
      if (this.sidvoList.length === 0) {
        this.$message({
          message: '请至少选择一条明细导入',
          type: 'warning'
        })
        return false
      }
      for (let i = 0; i < this.sidvoList.length; i++) {
        if (!(this.sidvoList[i].error === null || this.sidvoList[i].error === '')) {
          this.$confirm('列表中有错误信息不允许提交', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
      }
      this._addpurchasebatch(this.sidvoList)
    },
    async _addpurchasebatch(data) {
      try {
        this.Loading = true
        const { code, msg } = await addpurchasebatch(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.Loading = false
          this.uploadTableVisible = false
        }
      } finally {
        this.uploadTableVisible = false
        this.Loading = false
      }
    },
    selectionChange(row) {
      console.log(row)
      this.selectList = []
      row.map(e => {
        this.selectList.push(e.id)
      })
    },
    allPrice() {
      if (this.selectList.length === 0) {
        this.$message({
          message: '请至少选择一条数据修改',
          type: 'warning'
        })
        return false
      }
      this.modifyDialogFormVisible = true
    },
    modifySure() {
      const datas = {}
      datas.ids = this.selectList.join(',')
      datas.currencyType = this.modifyForm.currencyType
      datas.excludingTaxPrice = this.modifyForm.excludingTaxPrice
      datas.purchasePrice = this.modifyForm.purchasePrice
      this._updatePriceBatch(datas)
    },
    async _updatePriceBatch(data) {
      try {
        this.modifyUpLoding = true
        const { code, msg } = await updatePriceBatch(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.modifyUpLoding = false
          this.modifyDialogFormVisible = false
          this._pricepage()
        }
      } finally {
        this.modifyDialogFormVisible = false
        this.modifyUpLoding = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table .warning-row {
  color: red !important;
}
</style>
